import { render, staticRenderFns } from "./switch-business.vue?vue&type=template&id=7445f616&scoped=true"
import script from "./switch-business.vue?vue&type=script&lang=js"
export * from "./switch-business.vue?vue&type=script&lang=js"
import style0 from "./switch-business.vue?vue&type=style&index=0&id=7445f616&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_2aqslel7prtkk4onzp5d3vudie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7445f616",
  null
  
)

export default component.exports