<!-- 切换商户 -->
<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <div class="switch-business-content">
      <div class="a-flex-rsbc">
        <span class="a-fs-16 a-c-master a-fw-700">选择你要登录的商户号</span>
        <div class="a-flex-rfsc">
          <le-input
            v-model="companyName"
            style="padding: 0"
            label=""
            placeholder="请输入商户名称"
          />
          <el-button type="primary" class="a-ml-24" @click="getBusinessDatas">搜索</el-button>
        </div>
      </div>
      <div class="a-flex-rfsc a-mt-32 a-flex-wrap">
        <div
          v-for="(item, index) in businessList"
          :key="index"
          class="business-box a-flex-rfsfs mgr31"
          @click="changeBusiness(item)"
        >
          <img
            src="../../assets/icon/business-icon.png"
            style="width: 64px; height: 64px; margin-right: 21px"
          />
          <div>
            <div class="a-flex-rfsc a-pb-10">
              <span class="a-fs-16 a-c-master a-fw-700">{{ item.brand ? item.brand : "-" }}</span>
              <div v-if="company.id == item.id" class="tag">当前商户</div>
            </div>
            <span class="a-fs-14 a-c-normal" style="width: 270px">{{ item.name }}</span>
            <div class="a-flex-rfsc a-fs-14 a-mt-32">
              <span class="a-c-normal">累计用户</span>
              <span class="a-c-blue a-ml-11">{{ item.userNum | initNum }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="a-fs-16 a-c-master a-fw-700 a-mt-60">申请中的商户号</div>
      <div class="a-flex-rfsc a-mt-32 a-flex-wrap">
        <div
          v-for="(item, index) in businessWaitList"
          :key="index"
          class="business-box2 a-flex-rfsfs mgr31"
        >
          <img
            src="../../assets/icon/business-wait.png"
            style="width: 64px; height: 64px; margin-right: 21px"
          />
          <div>
            <div class="a-flex-rfsc a-pb-10">
              <span class="a-fs-16 a-c-master a-fw-700">{{ item.brand }}</span>
            </div>
            <span class="a-fs-14 a-c-normal" style="width: 270px">{{ item.name }}</span>
            <div class="a-flex-rfsc a-fs-14 a-mt-32">
              <span class="a-c-normal">注册状态</span>
              <span class="a-ml-11" :class="item.regState == 3 ? 'a-c-red' : 'a-c-blue'">
                {{ item.regStateText }}
              </span>
            </div>
          </div>
          <img
            v-if="item.regState == 2"
            src="../../assets/icon/business-wait-icon.png"
            class="audit-status"
            alt=""
          />
          <img
            v-if="item.regState == 3"
            src="../../assets/icon/business-reject-icon.png"
            class="audit-status"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "SwitchBusiness",

  filters: {
    initNum(val) {
      if (val > 10000) {
        return (val / 10000).toFixed(2) + "万";
      } else {
        return val;
      }
    },
  },
  data() {
    return {
      companyName: "", // 搜索商户名称
      businessList: [], // 可切换的商户列表
      businessWaitList: [], // 申请中的商户列表
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },
  mounted() {
    this.getBusinessDatas();
  },
  methods: {
    ...mapActions({
      getUserInfo: "user/getUserInfo",
      getCompanyInfo: "company/getCompanyInfo",
      getMenu: "menu/getMenu",
      getTodoNum: "todonum/getTodoNum",
    }),
    getBusinessDatas() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getCompanyChooseList,
          method: "get",
          params: {
            companyName: this.companyName,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.businessList = res.result.data.filter((res) => {
              return res.regState == 4 && res.status == 1;
            });
            this.businessWaitList = res.result.data.filter((res) => {
              return res.regState != 4 && res.status == 0;
            });
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    // 切换商户号
    changeBusiness(datas) {
      if (this.company.id == datas.id) return;
      this.$confirm("是否确认切换至该商户?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.chooseCompany,
            method: "get",
            params: {
              companyId: datas.id,
            },
          })
          .then((res) => {
            if (res.result.code == 0) {
              this.$message.success("操作成功");
              this.$store.dispatch("delOthersViews", this.$route);
              this.getUserInfo();
              this.getCompanyInfo();
              this.getMenu();
              this.getTodoNum();
            } else {
              this.$message.error(res.result.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-business-content {
  height: 100%;
  overflow-y: auto;
  background: #ffffff;
  font-size: 14px;
  padding: 43px 52px 0;
}

.business-box {
  background: url(../../assets/images/business-bg.png);
  background-size: 100% 100%;
  width: 451px;
  height: 179px;
  padding: 23px 33px 32px;
  margin-bottom: 24px;
  cursor: pointer;
}

.business-box2 {
  background: url(../../assets/images/business-bg1.png);
  background-size: 100% 100%;
  width: 451px;
  height: 179px;
  padding: 23px 33px 32px;
  position: relative;
  margin-bottom: 24px;
  cursor: pointer;
}

.mgr31 {
  margin-right: 31px;
}

.tag {
  border-radius: 5px;
  border: 1px solid #007aff;
  padding: 0 6px;
  font-size: 12px;
  color: #007aff;
  line-height: 20px;
  margin-left: 11px;
}

.audit-status {
  position: absolute;
  top: 0;
  right: 0;
  width: 53px;
  height: 43px;
}
</style>
